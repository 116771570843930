import React from 'react';
import { Layout } from '../../../components/Layout';

const SubdivisionSurveys = () => (
  <Layout
    title="Subdivision Surveys Calgary & Red Deer | Axiom Geomatics"
    description="Subdivision is taking a single parcel of land and dividing it into two or more parcels, each with their own land title."
    keywords="municipal survey, construction survey, subdivide land, subdivide, split land, create title, new land title, survey out, new lot creation"
  >
    <main>
      <h1>Subdivision Surveys Calgary & Area</h1>
      <p>
        Subdivision, in municipal survey parlance, is taking a single parcel of land and dividing it
        into two or more parcels, each with their own land title. This can be as simple as splitting
        a city lot into two parcels for infill development or as complex as taking hundreds of acres
        of space and creating a new neighbourhood. This is commonly called "land splitting" or
        "surveying out" a new property.
      </p>

      <p>
        <strong>Subdivision Surveys are a very lengthy process</strong>, and depending upon the
        municipality, may take from a few weeks to numerous months. There are a number of approvals
        and requirements along the way, as well as municipal fees to match.
      </p>

      <p>Some of the challenges with large-scale subdivisions include:</p>

      <ul>
        <li>requiring roads</li>
        <li>environmental reserves (or money in-lieu)</li>
        <li>municipal reserves (e.g. parks and playgrounds)</li>
        <li>utility right-of-ways</li>
        <li>and even unpaid taxes</li>
      </ul>

      <p>
        Failure to start the subdivision process early is the most common construction error we have
        seen. Ensure that you start your subdivision work for your project as soon as possible.
        Failure to do so may severely prolong the project with many costly and undesirable
        consequences.
      </p>

      <p>
        Condominiums are effectively subdivisions in three dimensional (3-D) space; please see our{' '}
        <a href="https://axiomgeomatics.ca/services/legal-surveys/condominium-services">
          Condominium Services page
        </a>{' '}
        for more specific information about condominiums.
      </p>
    </main>
  </Layout>
);

export default SubdivisionSurveys;
